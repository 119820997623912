import { firebaseConfig } from "./firebasedata";

export const environment = {
  production: true,
  envName: 'adpc',
  version: require('../../package.json').version,
  disabledFeatures: {
        public: true
  },
  routes: {
    adpc: {
      home: "https://app.adpc.net/practitioners-roster"
    }
  },
  firebaseConfig: firebaseConfig
};

export const URL_API_EMALSYS = 'https://apiemalsys.adpc.net/api/wsse/v3';
