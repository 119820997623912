<div class="row justify-content-center margin-b">
  <div id="register-component" class="col-xs-12 col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3">
    <div class="title">
      <h1>
        <span *ngIf="step==0">Create your organisation !</span>
        <span *ngIf="step==1">Check your email !</span>
        <span *ngIf="step==2"><span *ngIf="!existingUser">Tell us who you are and</span> create your organisation
          !</span>
      </h1>
    </div>
    <hr>
    <br>

    <div *ngIf="step==0">
      <h2 class="margin-b">Do we know each other ?</h2>
      <div class="register-component">
        <div class="form-group">
          <input class="form-control" [(ngModel)]="user.email" name="email" placeholder="Enter your email" type="email"
            #emailValue="ngModel" required
            pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+).+$$" />
          <div *ngIf="emailValue.invalid && (emailValue.dirty || emailValue.touched)" class="alert alert-danger">
            <div *ngIf="emailValue.errors.pattern">
              Please enter a valid email. (Missing @ and/or .fr/.com ... )
            </div>
            <div *ngIf="emailValue.errors.required">
              Please enter an email.
            </div>
          </div>
        </div>
      </div>
      <div class="register margin-b text-center">
        <button class="btn btn-red btn-lg" (click)='sendEmail()' [disabled]="emailValue.invalid">Next
          <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <div *ngIf="step==1">
      <p class="margin-b">We’ve sent a six-digit confirmation code to your email address. Enter it below to confirm your
        email address.</p>
      <form id="verify_code">
        <div class="split_input">
          <div class="confirmation_code_group">
            <input id="code1" name="code1" class="inline_input" maxlength="1" type="text" [(ngModel)]="code[0]"
              pattern="[0-9]" (keyup)="nextInput(code[0],'code2')" autofocus>
            <input id="code2" name="code2" class="inline_input" maxlength="1" type="text" [(ngModel)]="code[1]"
              pattern="[0-9]" (keyup)="nextInput(code[1],'code3')">
            <input id="code3" name="code3" class="inline_input" maxlength="1" type="text" [(ngModel)]="code[2]"
              pattern="[0-9]" (keyup)="nextInput(code[2],'code4')">
          </div>
          <div class="confirmation_code_span">—</div>
          <div class="confirmation_code_group">
            <input id="code4" name="code4" class="inline_input" maxlength="1" type="text" [(ngModel)]="code[3]"
              pattern="[0-9]" (keyup)="nextInput(code[3],'code5')">
            <input id="code5" name="code5" class="inline_input" maxlength="1" type="text" [(ngModel)]="code[4]"
              pattern="[0-9]" (keyup)="nextInput(code[4],'code6')">
            <input id="code6" name="code6" class="inline_input" maxlength="1" type="text" [(ngModel)]="code[5]"
              pattern="[0-9] " (keyup)="checkCode()">
          </div>
        </div>
        <div class="text-center invalid-code hidden">
          <p class="red">That code wasn't valid. Give it another go!</p>
        </div>
      </form>
    </div>

    <div *ngIf="step==2">
      <div class="register-component">
        <div class="form-group" *ngIf="!existingUser">
          <h2>Firstname</h2>
          <input class="form-control margin-b" [(ngModel)]="user.firstname" name="firstname"
            placeholder="Enter your firstname" type="text" #firstnameValue="ngModel" />
          <h2>Lastname</h2>
          <input class="form-control margin-b" [(ngModel)]="user.lastname" name="lastname"
            placeholder="Enter your lastname" type="text" #lastnameValue="ngModel" />
          <h2>Birthdate</h2>
          <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="1920:2018" [(ngModel)]="birthdate"
            [showIcon]="true" required #birthdateValue="ngModel"></p-calendar>
          <div *ngIf="birthdateValue.invalid && (birthdateValue.dirty || birthdateValue.touched)"
            class="alert alert-danger">
            <div *ngIf="birthdateValue.errors.required">
              Please enter a birthdate.
            </div>
          </div>
          <h2>Password</h2>
          <div class="password-field-container">
            <input class="form-control margin-b" [(ngModel)]="password" id="password" placeholder="Enter your password"
              type="password" #passwordValue="ngModel" required pattern="(?=.*[A-Z]).{6,}" />
            <i class="fa fa-eye eye-icon custom-icon-pos1" (click)="viewPassword()"></i>
          </div>
          <div *ngIf="passwordValue.invalid && (passwordValue.dirty || passwordValue.touched)"
            class="alert alert-danger">
            <div *ngIf="passwordValue.errors.pattern">
              Please enter a valid password. (Missing capital letter or too short)
            </div>
            <div *ngIf="passwordValue.errors.required">
              Please enter an password.
            </div>
          </div>
          <p class="margin-b">It must be at least 6 characters long and it must contain a capital letter.</p>
        </div>
        <div class="form-group">
          <h2>Organisation name *</h2>
          <input class="form-control margin-b" [(ngModel)]="organisation.name" name="organisationName"
            placeholder="Enter the organisation name" type="text" #organisationNameValue="ngModel" />
          <h2>Acronym *</h2>
          <input class="form-control margin-b" [(ngModel)]="organisation.acronym" name="organisationAcronym"
            placeholder="2 to 10 characters (letters and numbers)" type="text" #organisationAcronymValue="ngModel" />
          <h2>Type</h2>
          <select class="form-control margin-b" [(ngModel)]="organisation.type" name="organisationType" type="text"
            #organisationTypeValue="ngModel">
            <option value="Academic and Research Institution">Academic and Research Institution</option>
            <option value="Government">Government</option>
            <option value="International Organisation">International Organisation</option>
            <option value="Media">Media</option>
            <option value="Non-governmental Organisation">Non-governmental Organisation</option>
            <option value="Private Company">Private Company</option>
            <option value="Red Cross/Red Crescent Movement">Red Cross/Red Crescent Movement</option>
            <option value="Other">Other</option>
          </select>
          <h2>Website</h2>
          <input class="form-control margin-b" [(ngModel)]="organisation.website" name="organisationWebsite"
            placeholder="http://" type="text" #organisationWebsiteValue="ngModel" />
          <h2>Description</h2>
          <textarea class="form-control margin-b" rows="4" [(ngModel)]="organisation.description"
            name="organisationDescription" placeholder="Enter a description..." type="text"
            #organisationDescriptionValue="ngModel"></textarea>
          <!-- <h2>Subdomain *</h2>
          <div class="subdomain">
            <input class="form-control" [(ngModel)]="organisationSubdomain" name="organisationSubdomain"
              placeholder="Enter your subdomain" type="string" #organisationSubdomainValue="ngModel" required />
            <div>.emalsys.org</div>
          </div> -->
        </div>
      </div>
      <div class="register margin-b text-center">
        <!-- <button
          [disabled]="(!existingUser && (!user.firstname || !user.lastname || passwordValue?.invalid || !birthdate )) || (!organisation.name || !organisation.acronym || !organisationSubdomain)"
          class="btn btn-red btn-lg" (click)='registerAsOrganisation()'>Submit
          <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </button> -->

        <button
          [disabled]="(!existingUser && (!user.firstname || !user.lastname || passwordValue?.invalid || !birthdate )) || (!organisation.name || !organisation.acronym)"
          class="btn btn-red btn-lg" (click)='registerAsOrganisation()'>Submit
          <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div id="snackbarVerificationMailFailed">Verification code wrong</div>
<div id="snackbarRegisterOrganisationAlreadyExist">The organisation already exist</div>