<div id="home-features">

  <div class="home-features-illustration">
    <img src="../../../../../assets/getting-started/emalsys_app_iphone.png" />
  </div>

  <div class="features-list">
    <div *ngFor="let feature of featureList">
      <div class="feature-unit">
        <div class="image-feature">
            <i class="{{ feature.image }}"></i>
        </div>

        <div class="title-feature">
          {{ feature.title }}
        </div>
        <div class="text-feature">
          {{ feature.description }}
        </div>
      </div>
    </div>
  </div>

</div>
