/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home-features.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./home-features.component";
var styles_HomefeaturesComponent = [i0.styles];
var RenderType_HomefeaturesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomefeaturesComponent, data: {} });
export { RenderType_HomefeaturesComponent as RenderType_HomefeaturesComponent };
function View_HomefeaturesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "feature-unit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "image-feature"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "title-feature"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "text-feature"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.image, ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.description; _ck(_v, 7, 0, currVal_2); }); }
export function View_HomefeaturesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["id", "home-features"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "home-features-illustration"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "../../../../../assets/getting-started/emalsys_app_iphone.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "features-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomefeaturesComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.featureList; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_HomefeaturesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "home-features", [], null, null, null, View_HomefeaturesComponent_0, RenderType_HomefeaturesComponent)), i1.ɵdid(1, 49152, null, 0, i3.HomefeaturesComponent, [], null, null)], null, null); }
var HomefeaturesComponentNgFactory = i1.ɵccf("home-features", i3.HomefeaturesComponent, View_HomefeaturesComponent_Host_0, {}, {}, []);
export { HomefeaturesComponentNgFactory as HomefeaturesComponentNgFactory };
