import { Component } from '@angular/core';

import { environment } from '../../../../environments/environment';


@Component({
  selector   : 'home',
  templateUrl: './home.component.html',
  styleUrls  : ['./home.component.scss']
})


export class HomeComponent {

  // Variables declaration
  public environment = environment.envName;
  // Video url
  public videoHomepage   : string = 'https://www.youtube.com/embed/2Cus966EmyU';
  // Availability on app store and google play, images
  public appStoreImageUrl: string = '../../../../assets/getting-started/app-store.png';
  public googlePlayUrl   : string = '../../../../assets/getting-started/google-play.png';
  // Logos of social media
  public twitterUrl      : string = '../../../../assets/icons/twitter.png';
  public facebookUrl     : string = '../../../../assets/icons/facebook.png';
  public linkedinUrl     : string = '../../../../assets/icons/linkedin.png';
  // Year for copyright in page's footer
  public yearCopyright   : any = new Date().getFullYear();

  constructor() {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  // Method to return video url
  public displayVideo(url: string) {
    return url;
  }

}
