// Core
import { Component, OnInit } from '@angular/core';

// Services
import { RegisterService } from '../../../../services/register.service';
import { WSSEService } from '../../../../services/security/wsse.service';
import { NavigationService } from '../../../../services/utils/navigation.service';
import { LoggerService } from '../../../../services/utils/logger.service';
import { ToastService } from '../../../../services/utils/toast.service';
@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})


export class RegisterComponent implements OnInit {

  private success: boolean;

  constructor(
    private _navigationService: NavigationService,
    private _loggerService: LoggerService,
    private _toastService: ToastService,
    public _wsseService: WSSEService,
    public _registerService: RegisterService
  ) { }

  ngOnInit() {
  }


  register(password, email, firstName, lastName, birthdate) {
    if (this.getAge(birthdate)) {
      this._registerService.registerUser(firstName, lastName, email, birthdate).subscribe(
        res => {
        let salt = res;
        let saltedPassword = this._wsseService.saltPassword(salt, password);
        this._registerService.setPassword(email, saltedPassword).subscribe(
          success => {
          this._toastService.show("snackbarRegisterSuccess", "show", 10000);
        });
      },
        error => {
          if (error.status === 403) {
            this._toastService.show("snackbarRegisterMustBeInvitedFirst", "show", 3000);
          } else {
          this._toastService.show("snackbarRegisterAlreadyExist", "show", 3000);
        }
        });
    } else {
      this._toastService.show("snackbarRegisterMustBeOlder", "show", 3000);
    }

  }

  getAge(birthday: Date) {
    if (birthday) {
      birthday.setDate( birthday.getDate() + 1);
      let ageDif = Date.now() - birthday.getTime();
      let ageDate = new Date(ageDif);
      let age = Math.abs(ageDate.getUTCFullYear() - 1970);
      return age >= 18;
    }
  }
}
