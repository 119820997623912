import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmationUserComponent } from './confirmation-user/confirmation-user.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqComponent } from './faq/faq.component';
import { FeaturesComponent } from './features/features.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { RegisterAsOrganisationComponent } from './register-as-organisation/register-as-organisation.component';
import { RegisterComponent } from './register/register.component';
import { TermsComponent } from './terms/terms.component';
import { UserAgreementComponent } from './user-agreeement/user-agreement.component';


// Do not change the order of the routes, it matters
export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'organisation/register', component: RegisterAsOrganisationComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'getting-started', component: GettingStartedComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'confirm/:token', component: ConfirmationUserComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'user-agreement', component: UserAgreementComponent },
  { path: 'privacy', component: PrivacyComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicPagesRouting { }
