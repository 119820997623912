import { Component } from '@angular/core';

@Component({
  selector: 'register-birthdate',
  templateUrl: './register-birthdate.component.html',
  styleUrls: ['./register-birthdate.component.scss']
})
export class RegisterBirthdateComponent {

  public birthdate: Date;
  public yearRange: string;
  public validate: boolean;

  constructor() {
    this.birthdate = null;
    this.validate = false;
    this.yearRange = (new Date().getFullYear() - 71).toString() + ":" + (new Date().getFullYear() - 18).toString();
  }

  accept() {
    this.validate = true;
  }

  refuse() {
    this.validate = false;
  }
}
