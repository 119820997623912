import { Component } from '@angular/core';

@Component({
  selector   : 'pricing',
  templateUrl: './pricing.component.html',
  styleUrls  : ['./pricing.component.scss']
})


export class PricingComponent {

  constructor() {}

}
