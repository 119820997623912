<div id="register-birthdate-component">
  <div class="form-group">
    <p-calendar 
    [monthNavigator]="true" 
    [yearNavigator]="true" 
    [yearRange]=yearRange
    [(ngModel)]="birthdate"
    #birthdateValue="ngModel"  
    [showIcon]="true"
     placeholder="  Date of birth"
    required>
  </p-calendar>
    <div *ngIf="birthdateValue.valid"> {{ accept() }} </div>
    <div *ngIf="birthdateValue.invalid && (birthdateValue.dirty || birthdateValue.touched)"
         class="alert alert-danger">
      <div> {{ refuse() }} </div>
      <div *ngIf="birthdateValue.errors.required" >
        Please enter a birthdate.
      </div>
    </div>
  </div>
</div>
