<div id="public-component">
  <div class="public-component-banner" *ngIf="environment !== 'adpc'">
    <button class="to-remove" routerLink='/public'           i18n="@@homeTitle"         >
      <img class="button-image" src="assets/images/logo_emalsys.png"/></button>
    <!-- <button class="to-remove" routerLink='about'           i18n="@@aboutText"         >About</button> -->
    <!-- <button class="to-remove" routerLink='getting-started' i18n="@@gettingStartedText">Getting Started</button> -->
    <button class="to-remove" routerLink='features'        i18n="@@pricingText"       >Features</button>
    <!-- <button class="to-remove" routerLink='pricing'         i18n="@@pricingText"       >Pricing</button> -->
    <!-- <button class="to-remove" routerLink='career'          i18n="@@careerText"        >Careers</button> -->
    <button class="to-remove" routerLink='contact'            i18n="@@contactText"       >Contact Us</button>
    <span class="to-remove" >
       <span *ngIf="!cachedUser" (click)="displayLoginModal()" i18n="@@loginTitle">Login</span>
       <span *ngIf="cachedUser" (click)="goToDashboard()" i18n="@@dashboard">Dashboard</span>
         <!-- <span routerLink='login' i18n="@@loginTitle">Login</span> -->
        <!-- <span routerLink = "register" i18n="@@registerText" >Register</span> -->
    </span>
    <!-- <button class="to-remove" routerLink='user-agreement'  i18n="@@userAgreementText" >User Agreement</button> -->
    <!-- <button class="to-remove" routerLink='faq'             i18n="@@faqText"           >FAQ</button> -->
    <!-- <button class="to-remove" routerLink='terms'           i18n="@@termsText"         >Terms</button> -->
    <!-- <button class="to-remove" routerLink='privacy'         i18n="@@privacyText"       >Privacy</button> -->
  </div>

  <div class="public-component-body margin-b">
    <router-outlet></router-outlet>

    <div id="snackbarLoginFailPassword">Password incorrect; Password not matching.</div>
    <div id="snackbarLoginFailEmail">Email incorrect; No user registered with this email.</div>
    <div id="snackbarLoginFailNoOrganisation">Organisations not found. The user is not an organisation member</div>
    <div id="snackbarRegisterMustBeOlder"> You must be more than 18 years old to register. </div>
  </div>

  <footer class="footer margin-t" *ngIf="environment !== 'adpc'">
      <p class="text-white">
        &copy; {{ yearCopyright }} Relief Applications. All rights reserved. | Powered by <span class="relief-applications-footer">Relief Applications</span>
      </p>
      <ul i18n="@@homeFooterMenu">
        <li>
          <a routerLink='/public' href="#public-component">Home</a>
        </li>
        <!-- <li>
          <a routerLink='about'>About</a>
        </li> -->
        <li>
          <a routerLink='faq'>FAQ</a>
        </li>
        <!-- <li>
          <a routerLink='career'>Career</a>
        </li> -->
        <li>
          <a routerLink='terms'>Terms</a>
        </li>
        <li>
          <a routerLink='user-agreement'>User agreement</a>
        </li>
        <li>
          <a routerLink='privacy'>Privacy</a>
        </li>
      </ul>
  </footer>
</div>
