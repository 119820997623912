import { Component } from '@angular/core';

// Service
import { ContactUsService } from '../../../../services/api/contact-us.service';
import { ToastService } from '../../../../services/utils/toast.service';
import { LoggerService } from '../../../../services/utils/logger.service';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { NavigationService } from '../../../../services/utils/navigation.service';
@Component({
  selector: 'demo',
  templateUrl: './contact-us.component.html',
  styleUrls: ['contact-us.component.scss']
})

export class ContactUsComponent {

  // Variables declaration
  public contactUs: any;
  public checkRecaptcha: boolean;
  constructor(
    private _contactUs: ContactUsService,
    private _toastService: ToastService,
    private _loggerService: LoggerService,
    private _navigationService: NavigationService
  ) {
    this.contactUs = {};
    this.checkRecaptcha = false;
  }

  // Method code when submitting the contact form-job
  public formMethod(contact): void {
    this._loggerService.log("contact", contact);
    this._contactUs.sendEmailDisconnected(contact)
      .subscribe(res => {
        this.showToastContactUs();
        this._navigationService.goToPublic();
      });
  }

  showToastContactUs() {
    this._toastService.show("snackContact", "show", 3000);
  }
  resolved(captchaResponse: string) {
    let body = { response: captchaResponse };
    this._contactUs.checkRecaptchaResponse(body).subscribe(res => {
      let result = res;
      this.checkRecaptcha = result['success'];
    });

  }
}
