import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AlertInterface } from '../../../app/model/alert.interface';
import { OrganisationInterface } from '../../../app/model/organisation.interface';
import { ApiCacherService, Semaphore } from '../../utils/api-cacher.service';
import { HttpService } from '../http.service';
import { OrganisationDispatcherService } from '../organisation/organisation.dispatcher';
import { AlertService } from './alert.api';
import { AlertDispatcherService } from './alert.dispatcher';
export class AlertCachedService {

    private ORGANISATION_ALERTS = 'organisation_alerts_';
    private OLD_ORGANISATION_ALERTS = 'old_organisation_alerts_';
    private MY_ALERTS = 'my_alerts';
    private ALERT_DETAILS = 'alert_details_';


    private busy = new Semaphore();

    constructor(

        http: HttpClient,
        _httpService: HttpService,
        private _apiCacher: ApiCacherService,
        private _alertDispatcher: AlertDispatcherService,
        private _organisationDispatcher: OrganisationDispatcherService,
        private _alertService: AlertService
    ) {}


    public getOrganisationAlerts(organisationId: number) {
        this._apiCacher.do(
            this.busy.generate('getOrganisationAlerts'),
            this.ORGANISATION_ALERTS + organisationId,
            () => {
                return this._alertService.getOrgaAlerts(organisationId);
            }
        ).subscribe(
            (res: AlertInterface[]) => this._alertDispatcher.nextOrganisationAlerts(res)
        );
    }

    public getOldOrganisationAlerts(organisationid: number) {
        this._apiCacher.do(
            this.busy.generate(this.OLD_ORGANISATION_ALERTS),
            this.OLD_ORGANISATION_ALERTS,
            () => {
                return this._alertService.getOldOrgaAlerts(organisationid);
            }
        ).subscribe(
            (res: AlertInterface[]) => this._alertDispatcher.nextOldOrganisationAlerts(res)
        );
    }

    public getMyAlerts(organisationId: number) {
        return this._apiCacher.doWrapper(
            this.MY_ALERTS,
            () => this._alertService.getMyAlerts(organisationId)
        ).pipe(
            tap((res: AlertInterface[]) => this._alertDispatcher.nextMyAlerts(res))
        );
    }

    public getSingleAlertDetails(alertId: number) {
        this._apiCacher.doWrapper(
            this.ALERT_DETAILS + alertId,
            () => this._alertService.getAlertDetails(alertId)
        ).subscribe(
            // (res: AlertInterface) => this._alertDispatcher.nextAlert
        );
    }

    private refreshAlerts() {

        this._organisationDispatcher.getSelectedOrganisation()
            .subscribe(
                (organisation: OrganisationInterface) => {
                    this._apiCacher.removeIfPresent(this.ORGANISATION_ALERTS + organisation.id);
                    this.getOrganisationAlerts(organisation.id);
                });
    }


    public createAlert(body: FormData) {
        return this._alertService.createEvent(body)
            .pipe(
                tap(() => this.refreshAlerts())
            );
    }

    public deleteAlert(alertId: number) {
        return this._alertService.deleteAlert(alertId)
            .pipe(
                tap(() => this.refreshAlerts())
            );
    }

    public updateAlert(alertId: number, alert: AlertInterface) {
        return this._alertService.updateAlert(alertId, alert)
        .pipe(
            tap(() => this.refreshAlerts())
        );
    }


}
