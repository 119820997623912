import { Component } from '@angular/core';


@Component({
  selector   : 'features',
  templateUrl: './features.component.html',
  styleUrls  : ['./features.component.scss']
})


export class FeaturesComponent {

  constructor() {}

}
