/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./register-password.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./register-password.component";
var styles_RegisterPasswordComponent = [i0.styles];
var RenderType_RegisterPasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegisterPasswordComponent, data: {} });
export { RenderType_RegisterPasswordComponent as RenderType_RegisterPasswordComponent };
function View_RegisterPasswordComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Valid password "]))], null, null); }
function View_RegisterPasswordComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please enter a password. "]))], null, null); }
function View_RegisterPasswordComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Password must contains at least "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["6 characters"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["1 uppercase letter"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["1 lowercase letter"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Symbols not allowed"]))], null, null); }
function View_RegisterPasswordComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterPasswordComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterPasswordComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 8).errors.required; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v.parent, 8).errors.pattern; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_RegisterPasswordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["id", "register-password-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "form-group"], ["style", "position: relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "input", [["class", "form-control"], ["id", "passwordReg"], ["name", "password"], ["pattern", "^(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9]{6,30}$"], ["placeholder", "Password"], ["required", ""], ["type", "password"]], [[1, "required", 0], [1, "pattern", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.password = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(4, 16384, null, 0, i3.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵdid(5, 540672, null, 0, i3.PatternValidator, [], { pattern: [0, "pattern"] }, null), i1.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i3.RequiredValidator, i3.PatternValidator]), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(8, 671744, [["passwordValue", 4]], 0, i3.NgModel, [[8, null], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(10, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fa fa-eye eye-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewPass() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterPasswordComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterPasswordComponent_2)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = ""; _ck(_v, 4, 0, currVal_9); var currVal_10 = "^(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9]{6,30}$"; _ck(_v, 5, 0, currVal_10); var currVal_11 = "password"; var currVal_12 = _co.password; _ck(_v, 8, 0, currVal_11, currVal_12); var currVal_13 = i1.ɵnov(_v, 8).valid; _ck(_v, 13, 0, currVal_13); var currVal_14 = (i1.ɵnov(_v, 8).invalid && (i1.ɵnov(_v, 8).dirty || i1.ɵnov(_v, 8).touched)); _ck(_v, 15, 0, currVal_14); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).required ? "" : null); var currVal_1 = (i1.ɵnov(_v, 5).pattern ? i1.ɵnov(_v, 5).pattern : null); var currVal_2 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 10).ngClassValid; var currVal_7 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_RegisterPasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "register-password", [], null, null, null, View_RegisterPasswordComponent_0, RenderType_RegisterPasswordComponent)), i1.ɵdid(1, 49152, null, 0, i4.RegisterPasswordComponent, [], null, null)], null, null); }
var RegisterPasswordComponentNgFactory = i1.ɵccf("register-password", i4.RegisterPasswordComponent, View_RegisterPasswordComponent_Host_0, {}, {}, []);
export { RegisterPasswordComponentNgFactory as RegisterPasswordComponentNgFactory };
