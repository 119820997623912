import { Component } from '@angular/core';


@Component({
  selector   : 'register-password',
  templateUrl: './register-password.component.html',
  styleUrls  : ['./register-password.component.scss']
})


export class RegisterPasswordComponent {
  public password: string;

  constructor() {
    this.password = '';
  }

  viewPass() {
    let inputPassword = document.getElementById('passwordReg');
    if ( inputPassword.getAttribute('type') === 'password') {
      inputPassword.setAttribute('type', 'text');
    } else {
      inputPassword.setAttribute('type', 'password');
    }
  }
}
