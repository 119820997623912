<div id="register-name-component">
  <div class="form-group">
    <input class="form-control" [(ngModel)]="firstName" name="firstName" placeholder="First Name" type="text" required minlength="1" #firstNameValue="ngModel"/>
    <div *ngIf="firstNameValue.invalid && (firstNameValue.dirty || firstNameValue.touched)"
         class="alert alert-danger">
      <div *ngIf="firstNameValue.errors" i18n="@@pleaseEnterFirstName">
        Please enter a firstname.
      </div>
    </div>
  </div>
  <div class="form-group">
    <input class="form-control" [(ngModel)]="lastName" name="lastName" placeholder="Last Name" type="text" required minlength="1" #lastNameValue="ngModel"/>
    <div *ngIf="lastNameValue.invalid && (lastNameValue.dirty || lastNameValue.touched)"
         class="alert alert-danger">
      <div *ngIf="lastNameValue.errors" i18n="@@pleaseEnterLastName">
        Please enter a lastname.
      </div>
    </div>
  </div>
</div>
