import { Component, Input } from '@angular/core';
import { trigger, state, transition, style, animate, query, stagger, keyframes } from '@angular/animations';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector    : 'features-carousel',
  templateUrl : 'features-carousel.component.html',
  styleUrls   : ['features-carousel.component.scss'],
  providers   : [NgbCarouselConfig],
  animations : [
    trigger('carousel', [
      transition('* => *', [
        query('td', style({transform: 'translateX(-19400%)'})),
        query('td',
          stagger('0ms', [
            animate('2000000ms', style({transform: 'translateX(0)'}))
        ]))
      ])
    ])
  ]
})
export class FeaturesCarouselComponent  {

// List of logos
public imagesUrlPath: string = "../../../../../assets/image-clients/";
// Angular native animation is chosen to display from the last logo to the first
public imageSources: any;

constructor(config: NgbCarouselConfig){

    // Array of images to display
    this.imageSources = [
      { id: 1, url: this.imagesUrlPath + 'AA.png'},
      { id: 2, url: this.imagesUrlPath + 'AAH.png'},
      { id: 3, url: this.imagesUrlPath + 'ADRA.png'},
      { id: 4, url: this.imagesUrlPath + 'ALERT.png'},
      { id: 5, url: this.imagesUrlPath + 'CA.png'},
      { id: 6, url: this.imagesUrlPath + 'CARE.png'},
      { id: 7, url: this.imagesUrlPath + 'HAI.png'},
      { id: 8, url: this.imagesUrlPath + 'IR.png'},
      { id: 9, url: this.imagesUrlPath + 'MA.png'},
      { id: 10, url: this.imagesUrlPath + 'OC.png'},
      { id: 11, url: this.imagesUrlPath + 'OXFAM.png'},
      { id: 12, url: this.imagesUrlPath + 'PI.png'},
      { id: 13, url: this.imagesUrlPath + 'RCC.png'},
      { id: 14, url: this.imagesUrlPath + 'RI.png'},
      { id: 15, url: this.imagesUrlPath + 'SC.png'},
      { id: 16, url: this.imagesUrlPath + 'TA.png'},
      { id: 17, url: this.imagesUrlPath + 'VSO.png'},
      { id: 18, url: this.imagesUrlPath + 'WV.png'},
    ];

    // Configuration of carousel (NgbCarouselConfig)
    config.interval = 10000;
    config.wrap = true;
    config.keyboard = false;

}


}
