import { RosterPageService } from './modules/responder/organisation/roster/roster.page';
import { PushNotificationService } from './../services/api/pushnotifications.service';
import { GlobalPageService } from './../services/page/global.pager';
import { AlertPageService } from '../services/page/alert.manager.pager';
import { DashboardManagerPageService } from '../services/page/dashboard.manager.pager';
import { DeploymentOrdersDispatcher } from './../services/api/deploymentorders/deployment-orders.dispatcher';
import { DeploymentOffersDispatcher } from './../services/api/deploymentoffers/deployment-offers.dispatcher';
import { AvailabilitesDispatcher } from './../services/api/availabilites/availabilites.dispatcher';
import { RosterApiService } from './../services/api/roster/roster.api';
import { CacheNotificationsService } from './../services/utils/cache.notifications';
import { RosterCompliancesService } from './../services/api/roster/dependencies/compliances/roster-compliances.api';
import { RosterMembershipService } from './../services/api/roster/dependencies/membership/roster-membership.api';
import { OrganisationCompliancesService } from './../services/api/organisation/dependencies/compliances/organisation-compliances.api';
import { ProfileManagerGuardService } from './../services/security/profile-manager.guard';
import { OrganisationNewsService } from './../services/api/organisation/dependencies/news/organisation-news.api';
import { ManagerGuardService } from './../services/security/manager.guard';
import { OrganisationMembershipService } from './../services/api/organisation/dependencies/membership/organisation-membership.api';
import { GlobalDataService } from './../services/api/global/global.api';
import { CachedUserEventService } from '../services/api/caching/userevents.cacher';
import { MembersDispatcherService } from '../services/api/member/members.dispatcher';
import { CachedOrganisationService } from '../services/api/organisation/organisation.cacher';
import { AlertCachedService } from '../services/api/alert/alert.cacher';
import { AlertDispatcherService } from '../services/api/alert/alert.dispatcher';
import { RosterService } from '../services/api/roster/roster.cached';
import { RostersDispatcherService } from '../services/api/roster/rosters.dispatcher';
import { GlobalDispatcherService } from '../services/api/global/global.dispatcher';
import { BannerGuardService } from './../services/security/banner.guard';
import { BannerUpdater } from './modules/shared/banner/banner-updater.dispatcher';
import { OrganisationGuardService } from '../services/security/organisation.guard';
import { AuthGuardService } from './../services/security/auth.guard';
import { NgModule, Injector, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';

// Services
import { LoginService } from '../services/security/login.service';
import { RoutingGuardService } from '../services/security/routing.guard';
import { FeaturesGuardService } from '../services/security/features.guard';
import { WSSEService } from '../services/security/wsse.service';
import { CacheService } from '../services/storage/cache.service';
import { LoggerService } from '../services/utils/logger.service';
import { RightService } from '../services/utils/right.service';
import { NavigationService } from '../services/utils/navigation.service';
import { ToastService } from '../services/utils/toast.service';
import { LeafletService } from '../services/external/leaflet.service';
import { DisasterService } from '../services/external/disasters.service';
import { RegisterService } from '../services/register.service';
import { HttpService } from '../services/api/http.service';
import { ProfileService } from '../services/api/profile/profile.api';
import { OrganisationApiService } from '../services/api/organisation/organisation.api';
import { AlertService } from '../services/api/alert/alert.api';
import { UserEventAnswerService } from '../services/api/usereventanswer.service';
import { UserEventService } from '../services/api/userevent.service';
import { DeploymentOrderService } from '../services/api/deployment-order.service';
import { DeploymentService } from '../services/api/deployment.service';
import { SettingsService } from '../services/api/settings-service';
import { TagsService } from '../services/api/tag/tag.api';
import { ContactUsService } from '../services/api/contact-us.service';
import { DocumentationService } from '../services/api/documentation.service';
import { AdministrationSkillsService } from '../services/api/administration-skills.service';
import { ListService } from '../services/utils/list.service';
import { DocumentService } from '../services/api/document.service';
import { UserService } from '../services/api/user.service';
import { NotificationService } from '../services/api/notification.service';
import { EventListenerService } from '../services/utils/event-listener.service';
import { UpdateService } from '../services/utils/update.service';
import { FilterDispatcher } from './modules/shared/services/filter-dispatcher.service';
import { TagDispatcherService } from '../services/api/tag/tag.dispatcher';
import { ProfileDispatcherService } from '../services/api/profile/profile.dispatcher';
import { ConfirmationAlertModalService } from '../services/utils/confirmation-alert.service';
import { OrganisationDispatcherService } from '../services/api/organisation/organisation.dispatcher';
import { ApiCacherService } from '../services/utils/api-cacher.service';

import { EnvironmentChecker } from '../services/utils/environment-checker.service';

export let providersDefinition =  [
  LoginService,
  WSSEService,
  NavigationService,
  ProfileService,
  RegisterService,
  HttpService,
  OrganisationApiService,
  DisasterService,
  AlertService,
  UserEventAnswerService,
  UserEventService,
  DeploymentOrderService,
  DeploymentService,
  LeafletService,
  SettingsService,
  TagsService,
  ContactUsService,
  DocumentationService,
  AdministrationSkillsService,
  ListService,
  ToastService,
  DocumentService,
  RightService,
  AlertCachedService,
  CachedOrganisationService,
  CachedUserEventService,
  GlobalDataService,
  OrganisationMembershipService,
  OrganisationNewsService,
  OrganisationCompliancesService,
  // Roster services
  RosterMembershipService,
  RosterCompliancesService,
  RosterService,
  RosterService,
  // Guards
  AuthGuardService,
  OrganisationGuardService,
  ManagerGuardService,
  ProfileManagerGuardService,
  // AdminGuardService,
  RoutingGuardService,
  FeaturesGuardService,
  // Storage
  CacheService,
  // Logger
  LoggerService,
  EnvironmentChecker,
  UserService,
  EventListenerService,
  UpdateService,
  ConfirmationAlertModalService,
  ApiCacherService,
  // Dispatchers
  TagDispatcherService,
  ProfileDispatcherService,
  FilterDispatcher,
  OrganisationDispatcherService,
  GlobalDispatcherService,
  RostersDispatcherService,
  AlertDispatcherService,
  MembersDispatcherService,
  BannerUpdater,
  BannerGuardService,
  AvailabilitesDispatcher,
  DeploymentOrdersDispatcher,
  DeploymentOffersDispatcher,
  // Notifications
  CacheNotificationsService,
  NotificationService,
  PushNotificationService,
  // Page level services
  AlertPageService,
  DashboardManagerPageService,
  GlobalPageService,
  RosterPageService,
];
@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
  ],
  declarations: [],
  providers: providersDefinition
})
export class CoreModule {
  // This code has been used to resolve a certain problem
  // in a certain situation. Please don't do it at home.
  constructor(injector: Injector) {
    CacheNotificationsService.injector = injector;
    providersDefinition.forEach(element => {
      CacheNotificationsService.providers[element.name] = element;
    });
  }
}
