import {Component} from '@angular/core';

@Component({
  selector   : 'register-email',
  templateUrl: './register-email.component.html',
  styleUrls  : ['./register-email.component.scss']
})


export class RegisterEmailComponent {
  public email   : string;
  public validate: boolean;

  constructor() {
    this.email    = '';
    this.validate = false;
  }

  accept() {
    this.validate = true;
  }

  refuse() {
    this.validate = false;
  }
}
