import { Component } from '@angular/core';


@Component({
  selector   : 'register-name',
  templateUrl: './register-name.component.html',
  styleUrls  : ['./register-name.component.scss']
})


export class RegisterNameComponent {
  public firstName: string;
  public lastName: string;

  constructor() {
    this.firstName = '';
    this.lastName  = '';
  }
}
