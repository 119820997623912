import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// Services
import { RegisterService } from '../../../../services/register.service';
import { LoginService } from '../../../../services/security/login.service';
import { WSSEService } from '../../../../services/security/wsse.service';
import { CacheService } from '../../../../services/storage/cache.service';
import { LoggerService } from '../../../../services/utils/logger.service';
import { ToastService } from '../../../../services/utils/toast.service';
import { OrganisationInterface } from '../../../model/organisation.interface';
// Interface
import { UserInterface } from '../../../model/user.interface';
// Modal
import { PasswordModalComponent } from '../modals/password-modal/password-modal.component';
import { NavigationService } from './../../../../services/utils/navigation.service';
@Component({
  selector: 'app-register-as-organisation',
  templateUrl: './register-as-organisation.component.html',
  styleUrls: ['./register-as-organisation.component.scss']
})
export class RegisterAsOrganisationComponent implements OnInit {

  public user: UserInterface = new UserInterface();
  public organisation: OrganisationInterface = new OrganisationInterface();
  // public organisationSubdomain: string;
  public code: number[] = [];
  public password: string;
  public birthdate: Date;
  public step: number;
  public existingUser: boolean;
  public modalRef;
  constructor(
    public _wsseService: WSSEService,
    private _registerService: RegisterService,
    private _loggerService: LoggerService,
    private _loginService: LoginService,
    private _toastService: ToastService,
    public cache: CacheService,
    private _modalService: NgbModal,
    private _navigateService: NavigationService
  ) {
    this.step = 0;
    this.existingUser = false;
  }
  ngOnInit() {

  }

  nextStep() {
    this.step++;
  }

  // Step 1: give your email
  sendEmail() {
    let body = {
      email: this.user.email
    };
    this._registerService.sendVerifyEmail(body).subscribe(res => {
      this.nextStep();
    });
  }

  // Step 2: give the verification code
  nextInput(codeNumber, nextElement) {
    if (codeNumber) {
      document.getElementById(nextElement).focus();
    }
  }

  checkCode() {
    let code = this.code.join('');
    this._registerService.checkEmail(this.user.email, code).subscribe(
      res => {
        let result = res;
        if (result) {
          this.user = result;
          this.existingUser = true;
        }
        this.nextStep();
      }
      , error => {
        this._toastService.show("snackbarVerificationMailFailed", "show", 3000);
        this.code = [];
        document.getElementById('code1').focus();
      });
  }

  // Step 3: create account and organisation
  viewPassword() {
    let inputCurrentPassword = document.getElementById('password');
    if (inputCurrentPassword.getAttribute('type') === 'password') {
      inputCurrentPassword.setAttribute('type', 'text');
    } else {
      inputCurrentPassword.setAttribute('type', 'password');
    }
  }

  registerAsOrganisation() {
    if (this.getAge(this.birthdate) || this.existingUser) {
      if (this.existingUser) {
        this.createOrganisation(this.password);
      } else {
        this._registerService.registerAsOrganisation(this.user.firstname, this.user.lastname, this.user.email, this.birthdate).subscribe(res => {
          let salt = res;
          let saltedPassword = this._wsseService.saltPassword(salt, this.password);
          this._registerService.setPassword(this.user.email, saltedPassword).subscribe(data => {
            this.existingUser = true;
            this.createOrganisation(this.password);
          });
        });
      }
    } else {
      this._toastService.show("snackbarRegisterMustBeOlder", "show", 3000);
    }
  }

  getAge(birthday: Date) {
    if (birthday) {
      birthday.setDate(birthday.getDate() + 1);
      let ageDif = Date.now() - birthday.getTime();
      let ageDate = new Date(ageDif);
      let age = Math.abs(ageDate.getUTCFullYear() - 1970);
      return age > 18;
    }
  }

  createOrganisation(password) {
    this._loginService.requestSalt(this.user.email).subscribe(
      data => {
        if (!password) {
          this.displayPasswordModal();
        } else {
          let saltedPassword = this._wsseService.saltPassword(data, password);
          this._loginService.checkCredentials().subscribe(success => {
            this._registerService.registerOrganisation(this.organisation, '').subscribe(res => {
              // this._loginService.login(this.user.email,password)
              this._navigateService.goToHome();
              if (this.modalRef) {
                this.modalRef.close();
              }
            }, error => {
              this._toastService.show("snackbarRegisterOrganisationAlreadyExist", "show", 12000);
              if (this.modalRef) {
                this.modalRef.close();
              }
            });
          }, error => {
            this._toastService.show("snackbarLoginFailPassword", "show", 12000);
            if (this.modalRef) {
              this.modalRef.close();
            }
          });
        }
      }, error => {
        this._toastService.show("snackbarLoginFailEmail", "show", 12000);
        this.cache.clear();
        if (this.modalRef) {
          this.modalRef.close();
        }
      });
  }

  displayPasswordModal() {
    this.modalRef = this._modalService.open(PasswordModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    this.modalRef.componentInstance.password = this.password;
    this.modalRef.componentInstance.onValidate.subscribe(($e) => {
      this.createOrganisation($e);
    });
  }
}
