<div id="features-carousel">

    <table class="carousel-logos"  [@carousel]="imageSources.length">
      <!--Should we consider an infinite loop for from first to last element ?-->
      <tr>
          <td *ngFor="let img of imageSources">
            <img src = "{{img.url}}"/>
          </td>
          <td *ngFor="let img of imageSources">
            <img src = "{{img.url}}" />
          </td>
          <td *ngFor="let img of imageSources">
            <img src = "{{img.url}}" />
          </td>
          <td *ngFor="let img of imageSources">
            <img src = "{{img.url}}" />
          </td>
          <td *ngFor="let img of imageSources">
            <img src = "{{img.url}}" />
          </td>
          <td *ngFor="let img of imageSources">
            <img src = "{{img.url}}" />
          </td>
          <td *ngFor="let img of imageSources">
            <img src = "{{img.url}}" />
          </td>
          <td *ngFor="let img of imageSources">
            <img src = "{{img.url}}" />
          </td>
          <td *ngFor="let img of imageSources">
            <img src = "{{img.url}}" />
          </td>
          <td *ngFor="let img of imageSources">
            <img src = "{{img.url}}" />
          </td>
          <td *ngFor="let img of imageSources">
            <img src = "{{img.url}}" />
          </td>
      </tr>
    </table>

</div>
