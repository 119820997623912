import { Component } from '@angular/core';

@Component({
  selector   : 'home-features',
  templateUrl: './home-features.component.html',
  styleUrls  : ['./home-features.component.scss']
})

export class HomefeaturesComponent {

  // Variable declarations
  public featureList: any;
  public imagesFolderUrl: string = "../../../../../assets/global/";

  // constructor
  constructor() {
    this.featureList = [
      { id: 1, title: "Roster Management"              , image: "icon-user"     , description: "Create skill-based profile"},
      { id: 2, title: "Set availability"               , image: "icon-calendar" , description: "Provide emergency coordinator with your availability"},
      { id: 3, title: "Rosters"                        , image: "icon-people"   , description: "Join rosters for deployment opportunities"},
      { id: 4, title: "Alerts"                         , image: "icon-bell"     , description: "Monitor & respond to alerts"},
      { id: 5, title: "Monitor"                        , image: "icon-magnifier", description: "Track and monitor global disasters and organisation news"},
      { id: 6, title: "Documents Gallery"              , image: "icon-docs"     , description: "Store, publish and share private or public documents including pictures and videos"},
    ]
  }

}
