<div id="register-password-component">
  <div class="form-group" style="position: relative">
    <input class="form-control"
           [(ngModel)]="password"
           name="password"
           placeholder="Password"
           type="password"
           id="passwordReg"
           required
           pattern="^(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9]{6,30}$"
           #passwordValue="ngModel">
    <i class="fa fa-eye eye-icon" (click)="viewPass()"></i>
    <div *ngIf="passwordValue.valid"
         class="alert alert-success" i18n="@@validPassword">
      Valid password
    </div>
    <div *ngIf="passwordValue.invalid && (passwordValue.dirty || passwordValue.touched)"
         class="alert alert-danger">
      <div *ngIf="passwordValue.errors.required" i18n="@@pleaseEnterValidPassword">
        Please enter a password.
      </div>
      <div *ngIf="passwordValue.errors.pattern" i18n="@@passwordMustBe">
        <ul> Password must contains at least
          <li>6 characters</li>
          <li>1 uppercase letter</li>
          <li>1 lowercase letter</li>
          <li>Symbols not allowed</li>
        </ul>
      </div>
    </div>
  </div>
</div>
