/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./public-pages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./public-pages.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../services/utils/logger.service";
import * as i7 from "../../../services/storage/cache.service";
import * as i8 from "../../../services/utils/navigation.service";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
var styles_PublicPagesComponent = [i0.styles];
var RenderType_PublicPagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PublicPagesComponent, data: {} });
export { RenderType_PublicPagesComponent as RenderType_PublicPagesComponent };
function View_PublicPagesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.displayLoginModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Login"]))], null, null); }
function View_PublicPagesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToDashboard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Dashboard"]))], null, null); }
function View_PublicPagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "public-component-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "to-remove"], ["routerLink", "/public"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "button-image"], ["src", "assets/images/logo_emalsys.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "to-remove"], ["routerLink", "features"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Features"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "to-remove"], ["routerLink", "contact"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Contact Us"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "span", [["class", "to-remove"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PublicPagesComponent_2)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PublicPagesComponent_3)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "/public"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "features"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "contact"; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.cachedUser; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.cachedUser; _ck(_v, 14, 0, currVal_4); }, null); }
function View_PublicPagesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "footer", [["class", "footer margin-t"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["class", "text-white"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" \u00A9 ", " Relief Applications. All rights reserved. | Powered by "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "relief-applications-footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Relief Applications"])), (_l()(), i1.ɵeld(5, 0, null, null, 20, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [["href", "#public-component"], ["routerLink", "/public"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Home"])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["routerLink", "faq"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["FAQ"])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["routerLink", "terms"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Terms"])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "a", [["routerLink", "user-agreement"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["User agreement"])), (_l()(), i1.ɵeld(22, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 2, "a", [["routerLink", "privacy"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Privacy"]))], function (_ck, _v) { var currVal_3 = "/public"; _ck(_v, 8, 0, currVal_3); var currVal_6 = "faq"; _ck(_v, 12, 0, currVal_6); var currVal_9 = "terms"; _ck(_v, 16, 0, currVal_9); var currVal_12 = "user-agreement"; _ck(_v, 20, 0, currVal_12); var currVal_15 = "privacy"; _ck(_v, 24, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.yearCopyright; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 8).target; var currVal_2 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 12).target; var currVal_5 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵnov(_v, 16).target; var currVal_8 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_7, currVal_8); var currVal_10 = i1.ɵnov(_v, 20).target; var currVal_11 = i1.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_10, currVal_11); var currVal_13 = i1.ɵnov(_v, 24).target; var currVal_14 = i1.ɵnov(_v, 24).href; _ck(_v, 23, 0, currVal_13, currVal_14); }); }
export function View_PublicPagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["id", "public-component"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PublicPagesComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "public-component-body margin-b"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["id", "snackbarLoginFailPassword"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Password incorrect; Password not matching."])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["id", "snackbarLoginFailEmail"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email incorrect; No user registered with this email."])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["id", "snackbarLoginFailNoOrganisation"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Organisations not found. The user is not an organisation member"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["id", "snackbarRegisterMustBeOlder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You must be more than 18 years old to register. "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PublicPagesComponent_4)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.environment !== "adpc"); _ck(_v, 2, 0, currVal_0); _ck(_v, 5, 0); var currVal_1 = (_co.environment !== "adpc"); _ck(_v, 15, 0, currVal_1); }, null); }
export function View_PublicPagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "public", [], null, null, null, View_PublicPagesComponent_0, RenderType_PublicPagesComponent)), i1.ɵdid(1, 114688, null, 0, i4.PublicPagesComponent, [i5.Title, i6.LoggerService, i7.CacheService, i8.NavigationService, i9.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PublicPagesComponentNgFactory = i1.ɵccf("public", i4.PublicPagesComponent, View_PublicPagesComponent_Host_0, {}, {}, []);
export { PublicPagesComponentNgFactory as PublicPagesComponentNgFactory };
