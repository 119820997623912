<div class="row justify-content-center margin-b">
  <div id="register-component" class="col-xs-12 col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3">
    <h1 class="register" i18n="@@registerText">Register</h1>
    <hr>
    <br>
    <h2 class="margin-b" i18n="@@enterYourInfo">Enter your information: </h2>
    <form id="register-forms">
      <register-email    #emailOutput>   </register-email>
      <register-birthdate #birthdateOutput></register-birthdate>
      <register-name     #nameOutput>    </register-name>
      <register-password #passwordOutput></register-password>
    </form>
    <div class="term-condition">
      <p class="text-center margin-b" i18n="@@byClickingRegisterTermText">By clicking on Register, you agree to the <a href="public/terms" target="_blank">Terms & Conditions</a> of Emalsys</p>
    </div>
    <div class="register margin-b text-center">
      <button class="btn btn-red btn-block btn-lg"
              (click)='register(passwordOutput.password, emailOutput.email, nameOutput.firstName, nameOutput.lastName, birthdateOutput.birthdate )'
              [disabled]="emailOutput.email==='' || nameOutput.firstName==='' || nameOutput.lastName==='' || passwordOutput.password==='' || birthdateOutput.birthdate==null" i18n="@@registerText">Register</button>
    </div>
  </div>
</div>

<div id="snackbarRegisterSuccess">Registration Success ! Check your mails to activate your account.</div>
<div id="snackbarRegisterAlreadyExist">Email already registered.</div>
<div id="snackbarRegisterMustBeInvitedFirst">You must be invited by a manager.</div>
<div id="snackbarRegisterEmptyInput">A field is empty.</div>

