<div id="home-component" *ngIf="environment !== 'adpc'">
  <h1 class="register" i18n="@@homeTitle">Talent and emergency response management platform</h1>
  <div class="text-center" i18n="@@homeSubTitle">
    Staff rostering, alerts, deployments, real-time planning, sms, app notifications
  </div>

  <div class="text-center">
    <iframe width="45%" height="400" [src]='displayVideo(videoHomepage) | safeResourceUrl' frameborder="0" gesture="media" allowfullscreen></iframe>

    <p class="general-text-color message-above-ad" i18n="@@homeCatchyText">
      Manage your talents and emergency response anywhere in the world anytime!
    </p>
    <h2 class="general-text-color message-ad" i18n="@@homeCatchyMotto">Enjoy simplicity and efficiency, let's get started!</h2>

    <button class="btn btn-red" routerLink='/public/organisation/register' i18n="@@homeButtonOrga">JOIN AS ORGANISATION</button>
  </div>

  <div class="big-ad-area">
    <div class="big-ad-area-message" i18n="@@homeSecondTitle">
      Stay connected anywhere anytime!
    </div>
    <div i18n="@@homeSecondSubtitle">
      Emalsys app for responders
    </div>
    <div class="button-store">
     <a href="https://play.google.com/store/apps/details?id=org.reliefapps.emalsys"><img class="img-app-store" src="{{ googlePlayUrl }}" /></a>
        <span class="space-buttons"></span>
    <a href="https://itunes.apple.com/us/app/emalsys/id1163148916?mt=8"><img class="img-app-store" src="{{ appStoreImageUrl }}" /></a>
    </div>
  </div>

  <div class="features-container">
    <h1 class="register" i18n="@@homeThirdTile">Features you and your members will love</h1>
    <div class="features-small-text" i18n="@@homeThirdSubTitle">
      Check out what you can do with Emalsys !
    </div>
  </div>

  <home-features></home-features>

  <div class="start-area">
    <h1 class="title-start start-area-text">
      <div i18n="@@homeStopWaiting">
        Stop waiting
      </div>
      <div i18n="@@homeSartResponding">
        Start responding
      </div>
      <div>
        <button class="btn btn-red start-button" routerLink='organisation/register' i18n="@@homeGetStarted">Let's get started !</button>
      </div>
    </h1>
  </div>

  <div class="partners-container text-center margin-b margin-t">
    <h1 class="partners-title margin-b" i18n="@@homeFourthTitle">Some organisations on Emalsys</h1>
    <div class="features-small-text margin-b" i18n="@@homeFourthSubtitle">
        We work with a variety of fantastic clients and partners
    </div>
  </div>

  <features-carousel></features-carousel>

  <div class="love-container margin-t text-center custom-padding">
    <h1 class="love-text" i18n="@@homeFifthTitle">WE <i class="fa fa-heart heart"></i> NEW FRIENDS</h1>
    <div class="icons-social-media margin-b">
     <a onclick='window.open("http://twitter.com/Reliefapps/")'><img class="img-social-media" src='{{ twitterUrl }}'  /></a>
      <a onclick='window.open("http://www.linkedin.com/company/5250353/")'><img class="img-social-media" src='{{ linkedinUrl }}' /></a>
    </div>
  </div>

</div>

<div id="home-component" *ngIf="environment === 'adpc'">
  <div class="logo-container">
    <img class="emalsys-logo" src="../../../assets/images/emalsys_hand_background_red.png" alt="Emalsys">
  </div>
  <div class="window-redirecting">
    <div class="circle">
      <i class="fa fa-spinner custom-icon" aria-hidden="true"></i>
    </div>

    <div class="infos-redirecting">
      <h1 class="title-redirecting">Redirecting to Emalsys...</h1>
      <p class="text-redirecting">
        You will be redirected to Emalsys in a moment.
    </div>
  </div>
</div>
