<div id="register-email-component">
  <div class="form-group">
    <input class="form-control" [(ngModel)]="email" name="email" placeholder="Email" type="email" #emailValue="ngModel" required  pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+).+$$"/>
    <div *ngIf="emailValue.valid"> {{ accept() }} </div>
    <div *ngIf="emailValue.invalid && (emailValue.dirty || emailValue.touched)"
         class="alert alert-danger">
      <div> {{ refuse() }} </div>
      <div *ngIf="emailValue.errors.pattern" i18n="@@pleaseEnterValidEmail">
        Please enter a valid email. (Missing @ and/or .fr/.com ... )
      </div>
      <div *ngIf="emailValue.errors.required" i18n="@@pleaseEnterEmail">
        Please enter an email.
      </div>
    </div>
  </div>
</div>
