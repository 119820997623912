<div class="row justify-content-center margin-b">
  <div id="contact-us-component" class="col-xs-12 col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3">
    <h1 class="text-center register" i18n="@@contactUsTitle">CONTACT US</h1>
    <hr/>
    <br>
    <h2 class="margin-b" i18n="@@enterYourInfo">Enter your information: </h2>
    <div class="text-center">
      <form class="form-group custom-form" #contactForm="ngForm">
        <div class="margin-b">
            <p class="text-right"><i class="fa fa-star required-field"></i></p>
          <input
            class="form-control"
            name="firstName"
            id="firstName"
            placeholder="First Name"
            type="text"
            [(ngModel)]="contactUs.firstName"
            minLength="1"
            required pattern="[A-zÀ-ÿ_ \-]+"
            #firstName="ngModel"/>
        </div>
        <div class="error-message" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" i18n="@@contactUsErrorFirstName">
          The firstname is not valid
        </div>
        <div class="margin-b">
            <p class="text-right"><i class="fa fa-star required-field"></i></p>
          <input
            class="form-control"
            name="lastName"
            id="lastName"
            placeholder="Last Name"
            type="text"
            [(ngModel)]="contactUs.lastName"
            minLength="1"
            required pattern="[A-zÀ-ÿ_ \-]+"
            #lastName="ngModel"/>
        </div>
        <div class="error-message" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" i18n="@@contactUsErrorLastName">
          The lastname is not valid
        </div>
        <div class="margin-b">
          <input
            class="form-control"
            name="organisation"
            id="organisation"
            placeholder="Organisation"
            type="text"
            [(ngModel)]="contactUs.organisation" />
        </div>
        <div class="margin-b">
          <p class="text-right"><i class="fa fa-star required-field"></i></p>
          <input
            class="form-control"
            name="email" id="email"
            placeholder="Email"
            type="email"
            [(ngModel)]="contactUs.email"
            required pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+).+$$"
            #email="ngModel"/>
        </div>
        <div class="error-message" *ngIf="email.invalid && (email.dirty || email.touched)" i18n="@@contactUsErrorEmail">
          The email is invalid
        </div>
        <div class="margin-b">
          <p class="text-right"><i class="fa fa-star required-field"></i></p>
          <input
            class="form-control"
            name="subject"
            id="subject"
            placeholder="Subject"
            type="text"
            [(ngModel)]="contactUs.subject"
            #subject="ngModel"
            required />
        </div>
        <div class="error-message" *ngIf="subject.invalid && (subject.dirty || subject.touched)" i18n="@@contactUsErrorSubject">
          The subject is required
        </div>
        <div class="label-input-block margin-b">
          <p class="text-right"><i class="fa fa-star required-field"></i></p>
          <textarea
            class="form-control"
            name="message" id="message"
            type="text"
            [(ngModel)]="contactUs.message"
            placeholder="Enter your message here..."
            #message="ngModel"
            required>
          </textarea>
        </div>
        <div class="error-message" *ngIf="message.invalid && (message.dirty || message.touched)" i18n="@@contactUsErrorSubject">
          The message is required
        </div>
        <div class="captcha">
            <re-captcha  (resolved)="resolved($event)" siteKey="6LdIRgUTAAAAAMm3ETQDiGunpihgWYddlk2oa1xl"></re-captcha>
        </div>

        <div class="required-text" i18n="@@contactUsRequiredMessage">
          <p>Fields marked with <i class="fa fa-star required-field"></i> are required</p>
        </div>
      </form>
    </div>
    <div class="register margin-b text-center">
      <button class="btn btn-red btn-block btn-lg"
              type="submit"
              (click)="formMethod(contactUs)"
              [disabled]="contactForm.invalid || !checkRecaptcha"
              i18n="@@contactUsSendButton">
        Send message
      </button>
    </div>
    <div class="text-center">
      <div id="snackContact" i18n="@@contactUsConfirmation">Your message has been sent</div>
    </div>
  </div>
</div>
